var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loadingOneKid,
          expression: "loadingOneKid",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "ignore-button", attrs: { offset: 17, span: 7 } },
            [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "ignore-button",
                      attrs: {
                        effect: "dark",
                        content: "Xuất excel về máy",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { type: "success", icon: "el-icon-download" },
                        on: { click: _vm.handleDownloadExcel },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "ignore-button",
                      attrs: {
                        effect: "dark",
                        content: "In pdf",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { type: "success", icon: "el-icon-files" },
                        on: { click: _vm.handlePrint },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "dark",
                        content: "Cài đặt",
                        placement: "top-start",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { type: "success", icon: "el-icon-setting" },
                        on: { click: _vm.settingFontSize },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "pdf_container", style: _vm.customStyle }, [
        _c("br"),
        _c(
          "div",
          { staticStyle: { width: "96%", margin: "auto" } },
          [
            _vm.dataBillPreview[0].logoSchool !== null
              ? _c(
                  "el-row",
                  { staticClass: "header_kids" },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("img", {
                        attrs: {
                          id: "logo_onecam",
                          src: _vm.dataBillPreview[0].logoSchool,
                          alt: "",
                        },
                      }),
                    ]),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "line-height": "1.5",
                        },
                        attrs: { span: 12 },
                      },
                      [
                        _c("p", [
                          _c("b", {}, [
                            _vm._v(_vm._s(_vm.dataBillPreview[0].nameSchool)),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.dataBillPreview[0].addressSchool) + " "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [
                            _vm._v(
                              "PHIẾU THU HỌC PHÍ THÁNG " +
                                _vm._s(_vm.dataSearch.month) +
                                "/" +
                                _vm._s(_vm.dataSearch.createdYear) +
                                " - ĐỢT " +
                                _vm._s(_vm.dataSearch.collectionTurn)
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "el-row",
                  { staticClass: "header_kids" },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "line-height": "1.5",
                        },
                        attrs: { offset: 6, span: 12 },
                      },
                      [
                        _c("p", [
                          _c("b", {}, [
                            _vm._v(_vm._s(_vm.dataBillPreview[0].nameSchool)),
                          ]),
                          _c("br"),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.dataBillPreview[0].addressSchool) + " "
                            ),
                          ]),
                          _c("br"),
                          _c("b", [
                            _vm._v(
                              "PHIẾU THU HỌC PHÍ THÁNG " +
                                _vm._s(_vm.dataSearch.month) +
                                "/" +
                                _vm._s(_vm.dataSearch.createdYear) +
                                " - ĐỢT " +
                                _vm._s(_vm.dataSearch.collectionTurn)
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { staticClass: "print__left", attrs: { span: 12 } },
                  [
                    _c("span", [
                      _c("b", [_vm._v("Họ tên:")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.dataBillPreview[0].nameKid) +
                          " (" +
                          _vm._s(_vm.dataBillPreview[0].kidCode) +
                          ")"
                      ),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  { staticClass: "print__right", attrs: { span: 12 } },
                  [
                    _c("span", [
                      _c("b", [_vm._v("Mã:")]),
                      _vm._v(" .........."),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  { staticClass: "print__left", attrs: { span: 12 } },
                  [
                    _c("span", [
                      _c("b", [_vm._v("Lớp:")]),
                      _vm._v(" " + _vm._s(_vm.dataBillPreview[0].nameClass)),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  { staticClass: "print__right", attrs: { span: 12 } },
                  [
                    _c("span", [
                      _c("b", [_vm._v("Số:")]),
                      _vm._v(" .........."),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  { staticClass: "print__left", attrs: { span: 12 } },
                  [
                    _c("span", [
                      _c("b", [_vm._v("SĐT:")]),
                      _vm._v(" " + _vm._s(_vm.dataBillPreview[0].phoneKid)),
                    ]),
                  ]
                ),
                _c(
                  "el-col",
                  { staticClass: "print__right", attrs: { span: 12 } },
                  [
                    _c("span", [
                      _c("b", [_vm._v("Quyển số:")]),
                      _vm._v(" .........."),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("el-row", { staticStyle: { "margin-top": "6px" } }, [
              _c(
                "table",
                [
                  _c(
                    "tr",
                    {
                      staticStyle: {
                        "background-color": "#d1d5db",
                        "text-align": "center",
                      },
                    },
                    [
                      _c("th", { staticStyle: { width: "5%" } }, [
                        _vm._v("STT"),
                      ]),
                      _c("th", { staticStyle: { width: "40%" } }, [
                        _vm._v("Tên khoản thu"),
                      ]),
                      _c("th", { staticStyle: { width: "5%" } }, [
                        _vm._v("SL"),
                      ]),
                      _c("th", { staticStyle: { width: "15%" } }, [
                        _vm._v("Đơn giá"),
                      ]),
                      _c("th", { staticStyle: { width: "15%" } }, [
                        _vm._v("Giảm giá"),
                      ]),
                      _c("th", { staticStyle: { width: "20%" } }, [
                        _vm._v("Thành tiền"),
                      ]),
                    ]
                  ),
                  _vm._l(_vm.billTableForOneKidPreview, function (data, index) {
                    return _c("tr", { key: data.id }, [
                      _c("td", [_vm._v(_vm._s(index + 1))]),
                      _c("td", { staticStyle: { "text-align": "left" } }, [
                        _vm._v(_vm._s(data.nameKt)),
                      ]),
                      _c("td", [_vm._v(_vm._s(data.quantityKt))]),
                      _c("td", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm._f("formatCurrency")(data.priceKt)) +
                            "\n            "
                        ),
                      ]),
                      _c("td", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(
                          _vm._s(_vm._f("formatCurrency")(data.discountKt))
                        ),
                      ]),
                      _c("td", { staticStyle: { "text-align": "right" } }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm._f("formatCurrency")(data.moneyKt)) +
                            "\n            "
                        ),
                      ]),
                    ])
                  }),
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticStyle: { "background-color": "#d1d5db" },
                        attrs: { colspan: "6" },
                      },
                      [
                        _c("table", { staticStyle: { width: "100%" } }, [
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "font-weight": "bold",
                                  width: "25%",
                                },
                              },
                              [_vm._v("Tổng cộng")]
                            ),
                            _vm.dataBillPreview[0].beforeMoney >= 0
                              ? _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "font-weight": "bold",
                                      width: "25%",
                                    },
                                  },
                                  [_vm._v("Tiền thừa tháng trước")]
                                )
                              : _vm._e(),
                            _vm.dataBillPreview[0].beforeMoney < 0
                              ? _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "font-weight": "bold",
                                      width: "25%",
                                    },
                                  },
                                  [_vm._v("Tiền thiếu tháng trước")]
                                )
                              : _vm._e(),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  "font-weight": "bold",
                                  width: "25%",
                                },
                              },
                              [_vm._v("Số tiền đã trả")]
                            ),
                            _vm.dataBillPreview[0].afterMoney < 0
                              ? _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "font-weight": "bold",
                                      width: "25%",
                                    },
                                  },
                                  [_vm._v("Số tiền còn thiếu")]
                                )
                              : _vm._e(),
                            _vm.dataBillPreview[0].afterMoney >= 0
                              ? _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "center",
                                      "font-weight": "bold",
                                      width: "25%",
                                    },
                                  },
                                  [_vm._v("Số tiền còn thừa")]
                                )
                              : _vm._e(),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  width: "25%",
                                },
                              },
                              [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        _vm.totalBillForOneKid
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  width: "25%",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      _vm.dataBillPreview[0].beforeMoney
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  width: "25%",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      _vm.dataBillPreview[0].collectedMoney
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  width: "25%",
                                  color: "red",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      _vm.dataBillPreview[0].afterMoney
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "text-align": "left",
                                  "font-style": "italic",
                                },
                                attrs: { colspan: "4" },
                              },
                              [
                                _c("i", [
                                  _vm._v(
                                    "Số tiền bằng chữ: " +
                                      _vm._s(_vm.dataBillPreview[0].readMoney)
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ],
                2
              ),
            ]),
            _c("el-row", { staticStyle: { "margin-top": "5px" } }, [
              _c("span", [
                _c("b", [_vm._v("Ghi chú hóa đơn:")]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "white-space": "pre-line",
                      "font-style": "italic",
                    },
                  },
                  [_vm._v(_vm._s(_vm.dataBillPreview[0].noteExcel2))]
                ),
              ]),
              _c("i", [_vm._v(" " + _vm._s(_vm.dataBillPreview[0].expired))]),
            ]),
            _c(
              "el-row",
              { staticStyle: { "margin-top": "5px" } },
              [
                _c(
                  "el-col",
                  { staticClass: "print__left", attrs: { span: "8" } },
                  [
                    _c("span", [
                      _c("b", [_vm._v("STK:")]),
                      _vm._v(
                        " " + _vm._s(_vm.dataBillPreview[0].accountNumber)
                      ),
                    ]),
                  ]
                ),
                _c("el-col", { attrs: { span: "8" } }, [
                  _c("span", [
                    _c("b", [_vm._v("Chủ TK:")]),
                    _vm._v(" " + _vm._s(_vm.dataBillPreview[0].fullName)),
                  ]),
                ]),
              ],
              1
            ),
            _c("el-row", [
              _c("span", [
                _c("b", [_vm._v("Ngân hàng:")]),
                _vm._v(" " + _vm._s(_vm.dataBillPreview[0].bankName)),
              ]),
            ]),
            _c(
              "el-row",
              { staticClass: "footer_field" },
              [
                _vm.configData.statusShowDateTime
                  ? _c("el-col", { attrs: { offset: 6 } }, [
                      _c("span", [
                        _c("i", [
                          _vm._v(
                            _vm._s(_vm.currentTime) +
                              ", ngày " +
                              _vm._s(_vm.day) +
                              " tháng " +
                              _vm._s(_vm.month) +
                              " năm " +
                              _vm._s(_vm.year)
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("b", [_vm._v("Người nộp tiền")]),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("b", [_vm._v("Người lập phiếu")]),
                ]),
                _c(
                  "el-col",
                  {
                    staticStyle: { "margin-top": "70px" },
                    attrs: { offset: 12, span: 12 },
                  },
                  [_c("b", [_vm._v(_vm._s(_vm.dataBillPreview[0].userName))])]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "Thiết lập cỡ chữ",
                visible: _vm.dialogVisible,
                "close-on-click-modal": false,
                width: "300px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "8px" },
                      attrs: { span: 8 },
                    },
                    [_c("span", [_vm._v("Chọn cỡ chữ")])]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "70px" },
                          attrs: { placeholder: "Chọn cỡ chữ", size: "smail" },
                          model: {
                            value: _vm.customFontSize,
                            callback: function ($$v) {
                              _vm.customFontSize = $$v
                            },
                            expression: "customFontSize",
                          },
                        },
                        _vm._l(_vm.listFontSize, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("Đóng")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }